import Header from '../components/Header';
import Footer from '../components/Footer';
import microscope from '../images/microscope.webp';


const stats = [
    { label: 'Combined Years Experience', value: '100+' },
    { label: 'Projects Completed', value: '20+' },
]

export default function AboutPage() {

    return (
        <div className="bg-white">

            {/* HEADER */}
            <Header />

            {/* ABOUT */}
            <div className="bg-white py-24 sm:py-32">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto grid max-w-2xl grid-cols-1 items-start gap-x-8 gap-y-16 sm:gap-y-24 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                        <div className="lg:pr-4">
                            <div className="relative overflow-hidden rounded-3xl bg-gray-900 px-6 pb-9 pt-64 shadow-2xl sm:px-12 lg:max-w-lg lg:px-8 lg:pb-8 xl:px-10 xl:pb-10">
                                    <img
                                        className="absolute inset-0 h-full w-full object-cover"
                                        src={microscope}
                                        alt=""
                                    />



                                <figure className="relative isolate drop-shadow-md">
                                    <blockquote className="mt-6 text-xl font-semibold leading-8 text-white">
                                        <p>
                                            “Our unwavering dedication to excellence, combined with unmatched expertise and veteran leadership, guarantees a successful outcome for our clients every time.”
                                        </p>
                                    </blockquote>
                                    <figcaption className="mt-6 text-sm leading-6 text-gray-300">
                                        <strong className="font-semibold text-white">BST Team</strong>
                                    </figcaption>
                                </figure>
                            </div>
                        </div>
                        <div>
                            <div className="text-base leading-7 text-gray-700 lg:max-w-lg">
                                <p className="text-base font-semibold leading-7 text-sky-600">About BST</p>
                                <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                                    Our veteran values
                                </h1>
                                <div className="max-w-xl">
                                    <p className="mt-6">
                                        Bitterroot Services & Technology LLC (BST), is a Service Disabled Veteran Owned Small Business (SDVOSB). BST integrates and synchronizes Research and Development, Human Factors Engineering and Training Support, for our Military, Corporate, and Government clientele.
                                    </p>
                                    <p className="mt-8">
                                        BST combines over 100 years of Consultancy and Professional Services with a human resource reach-back into a pool of highly trained professionals. Our core competencies in Research and Development, Human Factors Engineering and Training Support, originate from a consortium of PhDs, an Unattended Ground Sensor expert, and the president is a retired and decorated 20-year Air Force Special Operations Officer.
                                    </p>
                                </div>
                            </div>
                            <dl className="mt-10 grid grid-cols-2 gap-8 border-t border-gray-900/10 pt-10 sm:grid-cols-4">
                                {stats.map((stat, statIdx) => (
                                    <div key={statIdx}>
                                        <dt className="text-sm font-semibold leading-6 text-gray-600">{stat.label}</dt>
                                        <dd className="mt-2 text-3xl font-bold leading-10 tracking-tight text-gray-900">{stat.value}</dd>
                                    </div>
                                ))}
                            </dl>
                        </div>
                    </div>
                </div>
            </div>

            {/* FOOTER */}
            <Footer />
        </div>
    )
}
